import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/MDXPageLayout.js";
import PageBanner from '../components/PageBanner';
import Button from '../components/Button';
import { newslettersUrl, clubInscriptionLink, discordLink } from '../settings';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <PageBanner styleId={1} title={'Le club'} description={'Rejoignez la communauté !'} mdxType="PageBanner" />
    <section id="one">
      <div className="inner">
        <h1>{`Rejoignez la communauté`}</h1>
        <p>{`En complément de votre inscription sur le site meetup, vous pouvez vous inscrire au Club.`}<br parentName="p"></br>{`
`}{`Le `}<strong parentName="p">{`Club Toulouse DataViz`}</strong>{`, est une communauté d’échange entre curieux, passionnés, amateurs et professionnels de la visualisation de données sur Toulouse
et ailleurs. Notre but est d’animer cette communauté en créant un réseau dynamique et réactif à travers lequel s’échangeront des informations sur
les activités du Toulouse DataViz, les nouveautés, les ateliers, les formations ainsi que diverses opportunités de mieux appréhender et visualiser des
données de tous secteurs, de toute nature et destinées à tout public.`}</p>
        <p>{`En vous inscrivant gratuitement au club :`}</p>
        <ul>
          <li parentName="ul">{`vous recevrez directement notre `}<strong parentName="li">{`newsletter`}</strong>{`,`}</li>
          <li parentName="ul">{`vous serez avertis en avant-première de nos `}<strong parentName="li">{`événements`}</strong>{` `}<em parentName="li">{`(meetup, Hackaviz..)`}</em></li>
          <li parentName="ul">{`vous pourrez échanger avec la `}<strong parentName="li">{`communauté`}</strong>{` via notre Discord Toulouse dataviz`}</li>
          <li parentName="ul">{`vous pourrez publier vos DataVisualisations sur le site TDV `}<em parentName="li">{`(sous conditions – nous contacter)`}</em></li>
        </ul>
        <ul className="actions" style={{
          paddingTop: "10px"
        }}>
  <li>
    <a href={clubInscriptionLink} target="_blank" rel="noreferrer" className="button medium">
      S'inscrire
    </a>
  </li>
  <li>
   <a href={"https://www.meetup.com/fr-FR/Meetup-Visualisation-des-donnees-Toulouse/"} target="_blank" rel="noreferrer" className="button medium">
      Rejoignez-nous sur Meetup
    </a>
  </li>
  <li>
    <a href={discordLink} target="_blank" rel="noreferrer" className="button medium">
      Voir le Discord
    </a>
  </li>
  <li>
    <a href={newslettersUrl} target="_blank" rel="noreferrer" className="button medium">
      Lire les newsletters
    </a>
  </li>
        </ul>
      </div>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      